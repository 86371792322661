 <template>
  <v-container>
    <v-navigation-drawer v-model="drawer" fixed temporary id="drawer">
      <div>
        <!--<v-col
          style="display: flex; justify-content: center; align-items: center"
          block
          :value="email.mail_id"
          v-for="(email, i) in $store.getters['auth/accounts']"
          v-bind:key="i"
          elevation="5"
        >
          <v-icon large left v-text="email.icon" />
          <v-badge
            style="margin-left: 20px; font-size: 16px; color: white"
            v-if="email.count"
            :content="email.count"
            >{{ email.mail_name }}</v-badge
          ><span
            v-else
            style="margin-left: 20px; font-size: 16px; color: white"
            v-text="email.mail_name"
          />
        </v-col>-->
        <v-divider></v-divider>
        <v-col
          style="
            display: flex;
            justify-content: center;
            color: var(--v-primary-base);
            align-items: center;
          "
          block
          >{{ selectedEmail }}</v-col
        >
      </div>
      <v-list class="bandeja">
        <v-list-item-group v-model="bandeja" color="primary" mandatory>
          <v-list-item
            class="py-3"
            link
            v-for="label in labels"
            :key="label.id"
            :value="label.id"
            @click="
              selectedThread = null;
              responder = false;
              drawer = false;
            "
          >
            <v-icon
              size="18"
              v-if="
                label.id === 'INBOX' ||
                label.name === 'Inbox' ||
                label.name === 'Bandeja de entrada'
              "
              class="mr-3"
              >$inbox</v-icon
            >
            <v-icon
              size="17"
              v-else-if="
                label.id === 'SENT' ||
                label.id === 'Sent' ||
                label.name === 'Sent Items' ||
                label.name === 'Bandeja de salida'
              "
              class="mr-3"
              >$send</v-icon
            >
            <v-icon size="17" v-else-if="label.id === 'UNREAD'" class="mr-3"
              >$mail</v-icon
            >
            <v-icon size="17" v-else-if="label.id === 'STARRED'" class="mr-3"
              >$star</v-icon
            >

            <v-icon size="17" v-else :color="label.color" class="mr-3"
              >$folder</v-icon
            >

            <v-list-item-title style="margin-left: 1em" v-on="on">
              {{ $t(label.name) }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <base-material-card :title="$t('email')" icon="mdi-email" id="mail">
      <v-row v-if="noLinkedMails">
        <v-row justify="center">
          <v-card :style="$vuetify.breakpoint.xsOnly ? 'width: 80%' : ''">
            <v-card-title class="pl-0 pl-sm-3">
              <v-row class="ma-2">
                <v-col>
                  <v-row justify="center">
                    <v-col style="text-align: center">
                      {{ $t("accounts.none.no_mail_services") }}
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-btn
                      class="ma-2"
                      @click="
                        $router.push({
                          name: 'UserSettings',
                          params: { type: 'connections' },
                        })
                      "
                      elevation="0"
                    >
                      <v-icon class="pr-2">$email</v-icon>
                      {{ $t("accounts.none.link_service") }}
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-title>
          </v-card>
        </v-row>
      </v-row>
      <v-row v-else>
        <v-col cols="12" class="py-0">
          <v-row
            tile
            height="54"
            color="transparent"
            class="d-flex"
            :style="
              $vuetify.breakpoint.xs
                ? 'position: sticky; top: 25px; z-index: 5;'
                : ''
            "
            align="center"
          >
            <v-col
              v-if="!$vuetify.breakpoint.xsOnly"
              style="max-width: 200px"
            ></v-col>
            <v-col class="py-0" v-if="!$vuetify.breakpoint.xsOnly">
              <v-tooltip
                bottom
                v-for="(email, i) in $store.getters['auth/accounts']"
                :key="i"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    outlined
                    dense
                    fab
                    small
                    :style="
                      !(
                        selectedMail === email.mail_id &&
                        selectedEmail === email.email
                      )
                        ? ''
                        : 'border: 3px solid var(--v-primary-base)'
                    "
                    elevation="0"
                    class="mx-1"
                    @click="changeService(email)"
                  >
                    <v-avatar v-if="email.mail_id === 1" size="35">
                      <img :src="email.image" />
                    </v-avatar>
                    <v-icon v-else size="25" v-text="email.icon" />
                  </v-btn>
                </template>
                {{ email.email }}
              </v-tooltip>
            </v-col>

            <v-col class="py-0" cols="3" v-if="!$vuetify.breakpoint.xsOnly">
              <v-row justify="end">
                <v-btn
                  class="mr-3 mb-1"
                  icon
                  outlined
                  dense
                  v-if="selected.length > 0"
                  @click="setReadMasive"
                  style="height: auto"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" size="18">$mail2</v-icon>
                    </template>
                    {{ $t("markAsUnread") }}
                  </v-tooltip>
                </v-btn>

                <v-menu v-if="false">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mr-3 mb-1"
                      icon
                      outlined
                      dense
                      v-on="on"
                      style="height: auto"
                    >
                      <template>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" size="18">$file</v-icon>
                          </template>
                          {{ $t("archivate") }}
                        </v-tooltip>
                      </template>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="label in labelsForArchive"
                      :key="label.id"
                      @click="archiveMassive(label.id)"
                      style="height: 30px"
                    >
                      {{ label.name }}
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-btn
                  class="mr-3 mb-1"
                  icon
                  outlined
                  dense
                  v-if="selected.length > 0"
                  @click="deleteMassive"
                  style="height: auto"
                >
                  <template>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" size="18">$delete</v-icon>
                      </template>
                      {{ $t("delete", { name: "" }) }}
                    </v-tooltip>
                  </template>
                </v-btn>
                <v-btn
                  class="mb-1"
                  icon
                  outlined
                  dense
                  @click="refresh"
                  style="height: auto; margin-right: 27px"
                  ><template>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">mdi-refresh</v-icon>
                      </template>
                      {{ $t("refresh") }}
                    </v-tooltip>
                  </template>
                </v-btn>
              </v-row>
            </v-col>
            <!--Header Formato movil-->

            <v-row
              class="pl-6"
              style="
                max-width: 100% !important;
                max-height: 71px;
                margin-top: -18px;
              "
              cols="6"
              v-if="$vuetify.breakpoint.xsOnly"
            >
              <v-card
                elevation="5"
                style="width: 100%; margin-top: 0px; margin-bottom: -16px"
              >
                <v-row>
                  <v-col
                    cols="2"
                    style="
                      padding-top: 14px !important;
                      max-width: 200px;
                      align-items: center;
                      display: flex;
                      justify-content: center;
                    "
                    class="pl-2 pt-2 pr-0"
                  >
                    <v-btn
                      v-if="$vuetify.breakpoint.smAndDown"
                      @click="drawer = !drawer"
                      fab
                      x-small
                      elevation="0"
                      class="mt-0"
                      v-on="on"
                      ><v-icon v-text="'mdi-menu-right'"
                    /></v-btn>
                  </v-col>

                  <v-col cols="2">
                    <v-btn
                      class="mr-0 mt-3"
                      icon
                      outlined
                      dense
                      @click="refresh"
                      style="height: auto"
                    >
                      <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="6">
                    <v-btn-toggle>
                      <v-btn
                        outlined
                        dense
                        style="width: 60px"
                        v-for="(email, i) in $store.getters['auth/accounts']"
                        v-bind:key="i"
                        elevation="5"
                        @click="changeService(email)"
                        :style="
                          !(
                            selectedMail === email.mail_id &&
                            selectedEmail === email.email
                          )
                            ? ''
                            : 'background-color: var(--v-primary-base)'
                        "
                      >
                        <v-avatar v-if="email.mail_id === 1" size="25">
                          <img :src="email.image" />
                        </v-avatar>
                        <v-icon v-else large v-text="email.icon" />
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                  <v-col cols="2" class="pl-1 pr-0">
                    <v-dialog
                      eager
                      :retain-focus="false"
                      v-model="composing"
                      max-width="500px"
                      persistent
                      max-height="600px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          elevation="0"
                          class="ma-2 pa-0"
                          @click="compose"
                          v-bind="attrs"
                          v-on="on"
                          style="
                            margin: 0px !important;
                            height: 30px;
                            min-width: 4px !important;
                            max-width: 6px;
                            padding: 13px 15px !important;
                            margin-top: 8px !important;
                          "
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </template>
                      <v-card style="overflow: hidden" id="newMessage">
                        <v-card-title>
                          <u>{{ $t("emails.new_message") }} </u>
                        </v-card-title>
                        <div class="close">
                          <v-btn
                            icon
                            @click="
                              composing = false;
                              editor = false;
                            "
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </div>
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" class="py-0">
                              <label>{{ $t("emails.to") }}</label>
                              <v-text-field
                                outlined
                                dense
                                v-model="newEmail.to"
                              ></v-text-field
                            ></v-col>
                            <v-col cols="12" class="py-0">
                              <label>{{ $t("emails.subject") }}</label>
                              <v-text-field
                                outlined
                                dense
                                v-model="newEmail.subject"
                              ></v-text-field
                            ></v-col>
                            <v-col cols="12">
                              <editor
                                :api-key="apiKey"
                                v-if="editor"
                                v-model="newEmail.content"
                                :init="init"
                              />
                              <!--<v-icon>$clip</v-icon
                            ><span style="color: white"
                              >Nombre archivo sadjunto</span
                            >-->
                            </v-col>
                            <v-col
                              cols="12"
                              v-for="(file, i) in files"
                              class="py-0"
                              :key="`file-${i}`"
                              style="color: var(--v-primary-base)"
                            >
                              <v-btn icon small @click="delFile(i)"
                                ><v-icon>mdi-close</v-icon></v-btn
                              >{{ file.name }}
                            </v-col>
                          </v-row>
                        </v-card-text>
                        <!--esta opción no está en el diseño
                            <v-text-field
                              outlined
                              dense
                              v-model="newEmail.cc"
                              label="Cc"
                            ></v-text-field>
                             
                            <v-text-field
                              outlined
                              dense
                              v-model="newEmail.bcc"
                              label="Bcc"
                            ></v-text-field>-->
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            outlined
                            @click="
                              composing = false;
                              editor = false;
                            "
                            style="height: 30px; width: 100px"
                            elevation="0"
                            >{{ $t("cancel") }}</v-btn
                          >
                          <v-btn
                            @click="sendMail"
                            dense
                            align="right"
                            elevation="0"
                            style="height: 30px"
                          >
                            <v-icon left>$send</v-icon>
                            {{ $t("emails.send") }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
                <v-row id="itemSelection" v-if="selected.length > 0">
                  <v-btn
                    class="mr-0"
                    icon
                    outlined
                    dense
                    v-if="selected.length > 0"
                    @click="setReadMasive"
                    style="height: auto"
                  >
                    <v-icon size="18">$mail2</v-icon>
                  </v-btn>

                  <v-menu v-if="selected.length > 0">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mr-0"
                        icon
                        outlined
                        dense
                        v-on="on"
                        style="height: auto"
                      >
                        <v-icon size="18">$file</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="label in labelsForArchive"
                        :key="label.id"
                        @click="archiveMassive(label.id)"
                        style="height: 30px"
                      >
                        {{ label.name }}
                      </v-list-item>
                    </v-list>
                  </v-menu>

                  <v-btn
                    class="mr-0"
                    icon
                    outlined
                    dense
                    v-if="selected.length > 0"
                    @click="deleteMassive"
                    style="height: auto"
                  >
                    <v-icon size="18">$delete</v-icon>
                  </v-btn>
                </v-row>
              </v-card>
            </v-row>
          </v-row>
          <!--Fin mobile-->
          <v-row>
            <v-col
              class="py-0"
              cols="6"
              :style="
                $vuetify.breakpoint.smAndUp
                  ? 'max-width: 200px'
                  : ' display: none'
              "
            >
              <v-card elevation="5" style="overflow: hidden; width: 170px">
                <v-list
                  class="bandeja"
                  :style="$vuetify.breakpoint.mdAndUp ? '' : 'padding-top: 0px'"
                  style="
                    max-height: 583px;
                    overflow-y: auto;
                    overflow-x: hidden;
                  "
                >
                  <v-dialog
                    v-model="composing"
                    max-width="500px"
                    max-height="600px"
                    eager
                    persistent
                    :retain-focus="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-row justify="center">
                        <v-btn
                          elevation="0"
                          class="mt-2 mb-md-5"
                          @click="compose"
                          v-bind="attrs"
                          v-on="on"
                          style="height: 30px"
                        >
                          <v-icon>mdi-plus</v-icon> {{ $t("new_email") }}
                        </v-btn>
                      </v-row>
                    </template>
                    <v-card style="overflow: hidden" id="newMessage">
                      <v-card-title>
                        <u>{{ $t("emails.new_message") }} </u>
                      </v-card-title>
                      <div class="close">
                        <v-btn
                          icon
                          @click="
                            composing = false;
                            editor = false;
                          "
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </div>
                      <v-card-text>
                        <form action data-vv-scope="email-form">
                          <v-row>
                            <v-col cols="12" class="py-0">
                              <label>{{ $t("emails.to") }}</label>
                              <v-text-field
                                outlined
                                dense
                                data-vv-validate-on="blur"
                                v-validate="'required|email'"
                                :error-messages="
                                  errors.collect('email-form.to')
                                "
                                data-vv-name="to"
                                v-model="newEmail.to"
                              ></v-text-field
                            ></v-col>
                            <v-col cols="12" class="py-0">
                              <label>{{ $t("emails.subject") }}</label>
                              <v-text-field
                                outlined
                                dense
                                v-model="newEmail.subject"
                              ></v-text-field
                            ></v-col>

                            <v-col cols="12">
                              <editor
                                :api-key="apiKey"
                                v-if="editor"
                                v-model="newEmail.content"
                                :init="init2"
                              />
                              <!--<v-icon>$clip</v-icon
                            ><span style="color: white"
                              >Nombre archivo sadjunto</span
                            >-->
                              <v-col
                                cols="12"
                                v-for="(file, i) in files"
                                class="py-0"
                                :key="`file-${i}`"
                                style="color: var(--v-primary-base)"
                              >
                                <v-btn icon small @click="delFile(i)"
                                  ><v-icon>mdi-close</v-icon></v-btn
                                >{{ file.name }}
                              </v-col>
                            </v-col>
                          </v-row>
                        </form>
                      </v-card-text>
                      <!--esta opción no está en el diseño
                            <v-text-field
                              outlined
                              dense
                              v-model="newEmail.cc"
                              label="Cc"
                            ></v-text-field>
                             
                            <v-text-field
                              outlined
                              dense
                              v-model="newEmail.bcc"
                              label="Bcc"
                            ></v-text-field>-->
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          outlined
                          @click="
                            composing = false;
                            editor = false;
                          "
                          style="height: 30px; width: 100px"
                          elevation="0"
                          >{{ $t("cancel") }}</v-btn
                        >
                        <v-btn
                          @click="sendMail"
                          dense
                          align="right"
                          elevation="0"
                          style="height: 30px; width: 100px"
                        >
                          <v-icon style="margin-right: 5px" size="14px"
                            >$send</v-icon
                          >
                          {{ $t("emails.send") }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-btn
                    v-if="$vuetify.breakpoint.smAndDown"
                    @click="labelsShow = !labelsShow"
                    fab
                    x-small
                    elevation="0"
                    class="mt-0"
                    ><v-icon
                      v-text="!labelsShows ? 'mdi-menu-down' : 'mdi-menu-up'"
                  /></v-btn>
                  <v-list-item-group
                    v-if="$vuetify.breakpoint.mdAndUp || labelsShow"
                    v-model="bandeja"
                    color="primary"
                    mandatory
                  >
                    <v-list-item
                      link
                      class="py-2"
                      v-for="label in labels"
                      :key="label.id"
                      :value="label.id"
                      @click="
                        selectedThread = null;
                        responder = false;
                        bandejaName = label.name;
                      "
                    >
                      <v-icon
                        size="22"
                        v-if="
                          label.id === 'INBOX' ||
                          label.name === 'Inbox' ||
                          label.name === 'Bandeja de entrada'
                        "
                        class="mr-3"
                        >$inbox</v-icon
                      >
                      <v-icon
                        size="22"
                        v-else-if="
                          label.id === 'SENT' ||
                          label.id === 'Sent' ||
                          label.name === 'Sent Items' ||
                          label.name === 'Bandeja de salida'
                        "
                        class="mr-3"
                        >$send</v-icon
                      >
                      <v-icon
                        size="22"
                        v-else-if="label.id === 'UNREAD'"
                        class="mr-3"
                        >$mail</v-icon
                      >
                      <v-icon
                        size="22"
                        v-else-if="label.id === 'STARRED'"
                        class="mr-3"
                        >$star</v-icon
                      >

                      <v-icon size="22" v-else :color="label.color" class="mr-3"
                        >$folder</v-icon
                      >

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-list-item-title style="margin-left: 1em" v-on="on">
                            {{ $t(label.name) }}
                          </v-list-item-title>
                        </template>
                        {{ $t(label.name) }}
                      </v-tooltip>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              sm="8"
              md="9"
              class="py-0"
              :style="$vuetify.breakpoint.mdAndDown ? '' : 'max-width: 80%'"
            >
              <v-card v-if="!selectedThread">
                <v-simple-table v-if="!$vuetify.breakpoint.xsOnly">
                  <thead>
                    <tr>
                      <th>
                        <v-icon
                          v-if="selected.length === emails.length"
                          @click="toggleSelect"
                          color="primary"
                          style="margin-left: -8px"
                          >mdi-check-circle</v-icon
                        >
                        <v-icon
                          v-else-if="selected.length === 0"
                          @click="toggleSelect"
                          color="primary"
                          style="margin-left: -8px"
                          >mdi-circle-outline</v-icon
                        >
                        <v-icon
                          @click="toggleSelect"
                          color="primary"
                          v-else
                          style="margin-left: -8px"
                          >mdi-minus-circle</v-icon
                        >
                      </th>
                      <th
                        v-if="
                          bandeja === 'SENT' ||
                          bandeja === 'Sent' ||
                          bandejaName === 'Sent Items'
                        "
                        style="
                          color: var(--v-primary-base) !important;
                          text-decoration: underline;
                          text-transform: uppercase;
                          font-size: 14px;
                          text-align: left !important;
                        "
                        v-text="$t('emails.to')"
                      />
                      <th
                        v-else
                        style="
                          color: var(--v-primary-base) !important;
                          text-decoration: underline;
                          text-transform: uppercase;
                          font-size: 14px;
                          text-align: left !important;
                        "
                        v-text="$t('emails.from')"
                      />
                      <th
                        style="
                          color: var(--v-primary-base) !important;
                          text-decoration: underline;
                          text-transform: uppercase;
                          font-size: 14px;
                          text-align: left !important;
                        "
                        v-text="$t('emails.subject')"
                      />
                      <th
                        style="
                          color: var(--v-primary-base) !important;
                          text-decoration: underline;
                          text-transform: uppercase;
                          font-size: 14px;
                          text-align: left !important;
                        "
                        v-text="$t('emails.date')"
                      />
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(threads, i) in emails"
                      :key="i"
                      v-bind:class="[
                        'email-row',
                        { unread: checkIfUnread(threads) },
                      ]"
                      @click="openEmail(threads)"
                    >
                      <td width="50">
                        <v-checkbox
                          @click.stop=""
                          v-model="selected"
                          :value="threads[0].id"
                          on-icon="mdi-check-circle"
                          off-icon="mdi-circle-outline"
                          multiple
                          hide-details
                        />
                      </td>
                      <td
                        v-if="
                          bandeja === 'SENT' ||
                          bandeja === 'Sent' ||
                          bandejaName === 'Sent Items'
                        "
                        width="200"
                        class="no-wrap"
                        style="max-width: 200px"
                      >
                        {{
                          threads[0].to.split("<")[0] !== ""
                            ? threads[0].to.split("<")[0]
                            : threads[0].to.split(/[<>]/)[1]
                        }}
                        <b v-if="threads.length > 1">({{ threads.length }}) </b>
                      </td>
                      <td
                        v-else
                        width="200"
                        class="no-wrap"
                        style="max-width: 200px"
                      >
                        {{
                          threads[0].from.split("<")[0] !== ""
                            ? threads[0].from.split("<")[0]
                            : threads[0].from.split(/[<>]/)[1]
                        }}
                        <b v-if="threads.length > 1">({{ threads.length }}) </b>
                      </td>
                      <td class="no-wrap" style="max-width: 200px">
                        {{ threads[0].subject }}
                      </td>
                      <td width="200" class="no-wrap">
                        {{ $d(new Date(threads[0].date), "short") }}
                      </td>
                      <td class="no-wrap" width="200">
                        <v-tooltip bottom v-if="checkIfUnread(threads)">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              @click.stop="markAsRead(threads)"
                              v-on="on"
                            >
                              <v-icon size="18">$mail2</v-icon></v-btn
                            >
                          </template>
                          <span>{{ $t("markAsRead") }}</span>
                        </v-tooltip>

                        <v-tooltip bottom v-else>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              @click.stop="markAsUnread(threads)"
                              v-on="on"
                            >
                              <v-icon>mdi-email-mark-as-unread</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t("markAsUnread") }}</span>
                        </v-tooltip>

                        <v-menu v-if="labelsForArchive.length">
                          <template v-slot:activator="{ on: onMenu }">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on: onTooltip }">
                                <v-btn
                                  icon
                                  v-on="{ ...onMenu, ...onTooltip }"
                                  :disabled="threads.length !== 1"
                                >
                                  <v-icon size="18">$file</v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t("archivate") }}</span>
                            </v-tooltip>
                          </template>
                          <v-list>
                            <v-list-item
                              v-for="label in labelsForArchive"
                              :key="label.id"
                              @click.stop="archive(threads, label.id)"
                              style="height: 30px"
                            >
                              {{ label.name }}
                            </v-list-item>
                          </v-list>
                        </v-menu>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn icon @click.stop="del(threads)" v-on="on">
                              <v-icon size="18">$delete</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t("delete", { name: "" }) }}</span>
                        </v-tooltip>
                      </td>
                    </tr>
                    <tr v-if="emails.length === 0">
                      <td
                        colspan="5"
                        class="primary--text"
                        style="text-align: center !important"
                      >
                        {{ $t("emails.no_messages") }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="5">
                        <v-btn
                          icon
                          class="ma-2"
                          @click="backPage"
                          :disabled="actualPage <= 1"
                        >
                          <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-btn
                          :disabled="emails.length < 10"
                          icon
                          class="ma-2"
                          @click="nextPage"
                        >
                          <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>

                        {{ this.actualEmails }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
                <!--Table mobile-->

                <v-simple-table v-else id="tablaMovil">
                  <!--<thead>
                    <tr>
                      <th width="10" class="pr-0">
                        <v-icon
                          v-if="selected.length === emails.length"
                          @click="toggleSelect"
                          color="primary"
                          style="margin-left: -13px"
                          >mdi-check-circle</v-icon
                        >
                        <v-icon
                          v-else-if="selected.length === 0"
                          @click="toggleSelect"
                          color="primary"
                          style="margin-left: -13px"
                          >mdi-circle-outline</v-icon
                        >
                        <v-icon
                          @click="toggleSelect"
                          color="primary"
                          v-else
                          style="margin-left: -13px"
                          >mdi-minus-circle</v-icon
                        >
                      </th>
                    </tr>
                  </thead>-->
                  <tbody>
                    <tr
                      v-for="(threads, i) in emails"
                      :key="i"
                      class="mobile-row"
                      v-bind:class="[
                        'email-row',
                        { unread: checkIfUnread(threads) },
                      ]"
                      @click="openEmail(threads)"
                    >
                      <td
                        id="selectorTable"
                        width="10"
                        style="
                          max-width: 30px;
                          padding-left: 1px;
                          padding-right: 0px;
                        "
                      >
                        <v-checkbox
                          @click.stop=""
                          v-model="selected"
                          :value="threads[0].id"
                          on-icon="mdi-check-circle"
                          off-icon="mdi-circle-outline"
                          multiple
                          class="ml-2 bigCheckbox"
                        />
                      </td>
                      <td
                        width="300"
                        class="no-wrap pt-2"
                        style="max-width: 300px"
                      >
                        <span>
                          {{
                            threads[0].from.split("<")[0] !== ""
                              ? threads[0].from.split("<")[0]
                              : threads[0].from.split(/[<>]/)[1]
                          }}
                          <b v-if="threads.length > 1"
                            >({{ threads.length }})
                          </b>
                        </span>
                        <br />
                        <span style="font-weight: normal !important">
                          {{ threads[0].subject }}
                        </span>
                        <br />
                        <span
                          style="
                            font-weight: normal;
                            color: var(--v-gris1-base);
                          "
                        >
                          {{ $d(new Date(threads[0].date), "short") }}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="5">
                        <v-btn
                          icon
                          class="ma-2"
                          @click="backPage"
                          :disabled="actualPage <= 1"
                        >
                          <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-btn icon class="ma-2" @click="nextPage">
                          <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                        {{ this.actualEmails }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
                <!-- <v-card-text>
              <v-row
                v-for="(threads, i) in emails"
                :key="i"
                v-bind:class="['email-row', {unread: checkIfUnread(threads)}]"
                @click="openEmail(threads)"
              >
                <v-col cols="3">
                  {{threads[0].from}}
                  <b v-if="threads.length > 1">({{threads.length}})</b>
                </v-col>
                <v-col cols="7">{{threads[0].subject}}</v-col>
                <v-col cols="2">{{$d(new Date(threads[0].date), 'short')}}</v-col>
              </v-row>
            </v-card-text>-->
              </v-card>
              <v-card v-if="selectedThread" style="height: 100%" elevation="5">
                <v-card-text>
                  <v-row>
                    <v-col class="pb-0" cols="2" md="1"
                      ><v-btn
                        @click="closeEmail"
                        elevation="0"
                        class="ma-1"
                        fab
                        x-small
                        ><v-icon>$arrow_back</v-icon></v-btn
                      ></v-col
                    >
                    <v-col cols="9" md="11" class="pt-5 no-wrap">{{
                      selectedThread[0].subject
                    }}</v-col>
                  </v-row>
                </v-card-text>

                <v-expansion-panels multiple v-model="selectedThread.opened">
                  <v-expansion-panel
                    v-for="email in selectedThread"
                    :key="email.id"
                  >
                    <v-expansion-panel-header
                      style="color: var(--v-gris1-base); padding-left: 30px"
                    >
                      <v-row style="max-width: 100%">
                        <v-col
                          cols="12"
                          md="7"
                          align="left"
                          class="pt-4 no-wrap"
                        >
                          {{
                            bandeja === "SENT" ||
                            bandeja === "Sent" ||
                            bandejaName === "Sent Items"
                              ? "Para: "
                              : "Desde: "
                          }}
                          {{
                            bandeja === "SENT" ||
                            bandeja === "Sent" ||
                            bandejaName === "Sent Items"
                              ? email.to
                              : email.from
                          }}
                        </v-col>
                        <v-spacer />
                        <v-col cols="7" md="3" class="pt-4">
                          {{ $d(new Date(email.date), "short") }}</v-col
                        >
                        <v-col cols="5" md="2" xl="1" class="pt-0">
                          <v-btn
                            icon
                            @click.stop="del(selectedThread)"
                            @click="closeEmail"
                          >
                            <v-icon size="18">$delete</v-icon>
                          </v-btn>
                          <v-menu>
                            <template v-slot:activator="{ on }">
                              <v-btn icon v-on="on">
                                <v-icon size="18">$file</v-icon>
                              </v-btn></template
                            >
                            <v-list>
                              <v-list-item
                                v-for="label in labelsForArchive"
                                :key="label.id"
                                @click.stop="archive(threads, label.id)"
                                style="height: 30px"
                              >
                                {{ label.name }}
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <!--<div
                        style="color: white; padding-left: 30px"
                        class="email-content"
                        v-html="email.content"
                      ></div>-->
                      <iframe
                        class="white"
                        width="100%"
                        :ref="`email-content-${email.id}`"
                        :height="heightEmail(email) || '10px'"
                        :srcdoc="email.content"
                        frameborder="0"
                      ></iframe>
                    </v-expansion-panel-content>
                    <div v-if="responder">
                      <v-card
                        elevation="5"
                        style="overflow: hidden; margin: 20px; padding: 30px"
                      >
                        <div style="color: var(--v-gris1-base)">
                          <v-btn
                            outlined
                            class="ma-2"
                            @click="responder = false"
                            style="height: 30px; width: 110px; font-size: 15px"
                          >
                            <v-icon style="margin-right: 2px"
                              >mdi-arrow-left</v-icon
                            >
                            <div>{{ $t("emails.back") }}</div>
                          </v-btn>
                          {{ email.from }}
                        </div>
                        <v-row>
                          <v-col cols="12">
                            <editor
                              :api-key="apiKey"
                              v-model="newEmail.content"
                              :init="init3"
                            />
                            <v-col
                              cols="12"
                              v-for="(file, i) in files"
                              class="py-0"
                              :key="`file-${i}`"
                              style="color: var(--v-primary-base)"
                            >
                              <v-btn icon small @click="delFile(i)"
                                ><v-icon>mdi-close</v-icon></v-btn
                              >{{ file.name }}
                            </v-col>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-row>
                            <v-spacer></v-spacer>
                            <v-col cols="12">
                              <v-btn
                                @click="sendMail"
                                dense
                                align="right"
                                elevation="0"
                                style="height: 30px; width: 100px"
                              >
                                <v-icon style="margin-right: 5px" size="14px"
                                  >$send</v-icon
                                >
                                {{ $t("emails.send") }}
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-row>
                      </v-card>
                    </div>
                    <div v-if="!responder">
                      <v-btn
                        outlined
                        class="ma-2"
                        @click="reply"
                        style="height: 30px; width: 130px; font-size: 15px"
                      >
                        <v-icon style="margin-right: 2px"
                          >mdi-arrow-left</v-icon
                        >
                        <div>{{ $t("emails.reply") }}</div>
                      </v-btn>
                      <!--<v-btn
                        outlined
                        class="ma-2"
                        style="height: 30px; width: 130px; font-size: 15px"
                      >
                        <div>{{ $t("emails.resend") }}</div>
                        <v-icon style="margin-left: 2px"
                          >mdi-arrow-right</v-icon
                        >
                      </v-btn>-->
                    </div>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card>
            </v-col>
          </v-row>
          <!--
          <v-alert
            v-model="alertdel"
            close-text="Cerrar alerta"
            color="var(--v-gris2-base)"
            :class="$vuetify.breakpoint.lgAndDown ? 'alerts-small' : 'alerts'"
            dark
            dismissible
            elevation="5"
            style="border-radius: 10px"
          >
            <div style="display: inline-block; color: var(--v-gris1-base)">
              {{ $t("emails.conversation_paperbin") }}
            </div>
            <a
              style="
                color: var(--v-primary-base);
                display: inline-block;
                text-align: right;
                width: 25%;
                text-decoration: none;
              "
              href="#"
              >{{ $t("emails.undo") }}</a
            >
          </v-alert>
          <v-alert
            v-model="alertarc"
            close-text="Cerrar alerta"
            color="var(--v-gris2-base)"
            :class="$vuetify.breakpoint.lgAndDown ? 'alerts-small' : 'alerts'"
            dark
            dismissible
            elevation="5"
            style="border-radius: 10px"
          >
            <div style="display: inline-block; color: var(--v-gris1-base)">
              {{ $t("emails.conversation_archived") }}
            </div>
            <a
              style="
                color: var(--v-primary-base);
                display: inline-block;
                text-align: right;
                width: 25%;
                text-decoration: none;
              "
              href="#"
              >{{ $t("emails.undo") }}</a
            >
          </v-alert>-->
        </v-col>
      </v-row>
    </base-material-card>
    <v-dialog v-model="fileDialog" max-width="500">
      <v-card id="email-files">
        <v-card-title
          style="text-transform: uppercase; text-decoration: underline"
          >{{ $t("attach", { name: $tc("files") }) }}
        </v-card-title>
        <div class="close">
          <v-btn
            icon
            @click="
              fileDialog = false;
              files = [];
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text class="pb-1">
          <v-row>
            <v-col cols="12" class="pt-4 pb-0">
              <file-upload
                style=""
                :multiple="true"
                :drop="true"
                :drop-directory="false"
                accept="image/*, .doc,.docx,.xls,.xlsx"
                v-model="files"
                ref="upload"
                class="pt-2 file-upload"
              >
                <div
                  style="
                    border: dashed var(--v-primary-base) 3px;
                    border-radius: 10px;
                    min-height: 50px;
                    text-align: center;
                    color: var(--v-primary-base);
                  "
                >
                  <div v-if="files.length" style="height: 100%">
                    <template v-for="(file, i) in files">
                      <v-chip
                        v-if="i < 2"
                        :key="file.id"
                        outlined
                        color="primary"
                        style="
                          max-width: 30%;
                          vertical-align: middle;
                          margin-top: 6px;
                        "
                        class="no-wrap mx-1"
                        >{{ file.name }}
                      </v-chip>
                      <v-chip
                        v-if="i == 2"
                        :key="file.id"
                        outlined
                        color="primary"
                        class="mx-1"
                        style="margin-top: 6px"
                      >
                        + {{ files.length - 2 }}
                        {{ $tc("file", files.length - 2) }}
                      </v-chip>
                    </template>
                  </div>
                  <!--Estilo movil-->
                  <div v-if="files.length === 0">
                    <h4 class="message pt-3" style="font-weight: normal">
                      <v-icon size="12" color="primary">$clip</v-icon>
                      {{ $t("designs.drop") }}
                    </h4>
                  </div>

                  <div class="example-btn"></div>
                </div>
              </file-upload>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pr-4">
          <div class="flex-grow-1"></div>
          <v-btn
            outlined
            @click="
              fileDialog = false;
              files = [];
            "
            style="height: 25px; width: 100px"
            elevation="0"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            @click="fileDialog = false"
            style="
              width: 100px !important;
              color: #363533;
              height: 25px;
              padding-top: 10px !important;
            "
            elevation="0"
          >
            <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
            {{ $t("save", { name: $t("") }) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>


<script>
import { mapActions } from "vuex";

export default {
  name: "Email",
  data() {
    return {
      init: {
        height: 200,
        content_style: "* {color: white}",
        menubar: false,
        selector: "#instance1",
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
        ],
        toolbar:
          "mybutton bold italic underline link " +
          "undo redo | fontsizeselect formatselect|bold italic underline | forecolor backcolor |" +
          "alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | ",

        setup: (editor) => {
          editor.ui.registry.addButton("mybutton", {
            tooltip: "Upload",
            icon: "upload",
            onAction: () => {
              this.fileDialog = true;
              //console.log("Adjuntar Archivo", this.fileDialog);
            },
          });
        },
      },
      init2: {
        height: 200,
        content_style: "* {color: white}",
        menubar: false,
        selector: "#instance2",
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
        ],
        toolbar:
          "mybutton bold italic underline link " +
          "undo redo | fontsizeselect formatselect| forecolor backcolor " +
          "alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | ",

        setup: (editor) => {
          editor.ui.registry.addButton("mybutton", {
            tooltip: "Upload",
            icon: "upload",
            onAction: () => {
              this.fileDialog = true;
              //console.log("Adjuntar Archivo", this.fileDialog);
            },
          });
        },
      },
      init3: {
        height: 200,
        content_style: "* {color: white}",
        menubar: false,
        selector: "#instance3",
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
        ],
        toolbar:
          "mybutton bold italic underline link " +
          "undo redo | fontsizeselect formatselect|bold italic underline | forecolor backcolor |" +
          "alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | ",
        setup: (editor) => {
          editor.ui.registry.addButton("mybutton", {
            tooltip: "Upload",
            icon: "upload",
            onAction: () => {
              this.fileDialog = true;
              //console.log("Adjuntar Archivo", this.fileDialog);
            },
          });
        },
      },
      fileDialog: false,
      files: [],
      drawer: false,
      emails: [],
      selectedThread: null,
      selectedMail: null,
      selectedEmail: null,
      itemsPerPage: 10,
      responder: false,
      composing: false,
      token_page: [null],
      actual_token_page: null,
      noLinkedMails: false,
      selected: [],
      labelsPrev: [],
      nameEmail: null,
      labels: [],
      alertdel: false,
      alertarc: false,
      bandeja: [],
      bandejaName: "",
      show: [{ title: "Enviar" }, { title: "Enviados" }],
      labelsShow: false,
      editor: false,
      newEmail: {
        to: "",
        cc: "",
        bcc: "",
        subject: "",
        content: "",
        mail_id: null,
      },
    };
  },
  components: {
    Editor: () => import("@tinymce/tinymce-vue"),
    FileUpload: () => import("vue-upload-component"),
  },
  mounted() {
    //this.setInit();

    let mails = this.$store.getters["auth/accounts"];
    console.log("mails", mails, mails.length);
    if (mails.length == 0) {
      console.log(mails.length);
      this.noLinkedMails = true;
    } else {
      console.log("setEmail");
      this.selectedMail = mails[0].mail_id;
      this.selectedEmail = mails[0].email;

      if (this.$route.params.email) {
        //console.log("email", this.$route.params.email);
        this.compose();
        this.newEmail.to = this.$route.params.email;
      }
    }
    this.loadEmailLabels();
    this.loadEmailList();
    this.loadNoReadsEmails();
  },
  computed: {
    apiKey() {
      return process.env.VUE_TINYMCE_API_KEY;
    },
    allIds() {
      //console.log(this.emails);
      return [];
    },
    actualPage() {
      return this.token_page.length - 1;
    },
    actualEmails() {
      let text = "";
      text += (this.actualPage - 1) * this.itemsPerPage + 1;
      text += "-";
      text += this.actualPage * this.itemsPerPage;
      console.log(text);
      if (this.actualPage <= 0) {
        text = "";
      }
      return text;
    },
    labelsForArchive() {
      return this.labels.filter(
        (x) =>
          x.id !== "INBOX" &&
          x.id !== "SENT" &&
          x.id !== "Sent" &&
          x.system !== true
      );
    },
  },
  watch: {
    bandeja: {
      handler() {
        this.actual_token_page = null;
        this.token_page = [null];
        this.loadEmailList();
      },
      deep: true,
    },
  },
  methods: {
    heightEmail(email) {
      console.log(`email-content-${email.id}`);
      setTimeout(() => {
        let iframe = this.$refs[`email-content-${email.id}`];
        console.log(iframe[0].contentWindow.document.body.scrollHeight);
        iframe[0].height =
          +iframe[0].contentWindow.document.body.scrollHeight + 20 + "px";
      }, 500);

      //console.log(iframe.clientHeight)
      return "300px";
    },
    delFile(index) {
      this.$confirm("¿Seguro que quieres eliminar?", "", "").then(() =>
        this.files.splice(index, 1)
      );
    },
    setInit() {},
    ...mapActions("email", [
      "getEmails",
      "getLabels",
      "sendEmail",
      "setAsRead",
      "setAsUnread",
      "getUnreadEmails",
      "deleteEmail",
      "deleteEmails",
      "setEmailsAsRead",
      "archiveEmail",
      "archiveEmails",
    ]),
    loadNoReadsEmails() {
      this.getUnreadEmails();
    },

    loadEmailList() {
      this.selected = [];
      this.getEmails({
        mail_id: this.selectedMail,
        email: this.selectedEmail,
        itemsPerPage: this.itemsPerPage,
        page: this.actualPage + 1,
        token_page: this.actual_token_page,
        bandeja: this.bandeja,
      }).then((emails) => {
        if (emails.data[0]) {
          if (
            this.bandeja === "SENT" ||
            this.bandeja === "Sent" ||
            this.bandejaName === "Sent Items"
          )
            this.nameEmail = emails.data[0][0].from;
          else this.nameEmail = emails.data[0][0].to;
        }

        this.emails = emails.data;
        //console.log(emails.data);

        this.token_page.push(emails.token_page);
      });
    },
    toggleSelect() {
      if (this.selected.length) {
        this.selected = [];
      } else {
        this.selected = this.emails.map((x) => x[0].id);
      }
    },
    loadEmailLabels() {
      this.getLabels({
        mail_id: this.selectedMail,
        email: this.selectedEmail,
      }).then((data) => {
        if (data) {
          this.labels = [];
          this.labelsPrev = data;
          this.labelsPrev.forEach((l) => {
            switch (l.name) {
              case "INBOX":
              case "Inbox":
              case "Bandeja de entrada":
                this.labels.splice(0, 0, l);
                break;
              case "SENT":
              case "Bandeja de salida":
              case "Sent Items":
                this.labels.splice(1, 0, l);
                break;
              default:
                this.labels.push(l);
                break;
            }
          });
          this.bandeja = this.labels[0];
          this.loadEmailList();
        } else if ($store.getters["auth/accounts"].length) {
          this.$confirm(this.$t("google_permision_error")).then(() => {
            this.$router.push({
              name: "UserSettings",
              params: { type: "connections" },
            });
          });
        }
      });
    },
    changeService(service) {
      this.selectedMail = service.mail_id;
      this.selectedEmail = service.email;
      this.actual_token_page = null;
      this.token_page = [null];

      this.loadEmailLabels();
    },
    nextPage() {
      this.actual_token_page = this.token_page[this.actualPage];

      this.loadEmailList();
    },

    refresh() {
      this.token_page = this.token_page.slice(0, this.actualPage);
      this.loadEmailList();
    },
    backPage() {
      //Eliminamos los dos ultimo de la lista
      this.token_page = this.token_page.slice(0, this.actualPage - 1);
      if (this.actualPage === -1) {
        this.token_page.push(null);
      }

      //Cogemos como actual token page lenght - 1
      this.actual_token_page = this.token_page[this.actualPage];

      this.loadEmailList();
    },
    openEmail(email) {
      this.selectedThread = email;
      let i = 0;
      for (i; i < email.length; ++i) {
        if (this.selectedThread[i].unread) {
          email[i].unread = false;
          this.$store.commit("email/DECREASE_UNREAD_EMAILS", this.selectedMail);
        }
        this.setAsRead({
          message_id: this.selectedThread[i].id,
          mail_id: this.selectedMail,
          email: this.selectedEmail,
        });
      }
      if (!email.hasOwnProperty("opened")) {
        this.$set(this.selectedThread, "opened", [
          this.selectedThread.length - 1,
        ]);
      }
    },
    markAsRead(email) {
      //console.log(email);
      for (let i = 0; i < email.length; ++i) {
        if (email[i].unread) {
          email[i].unread = false;
          this.$store.commit("email/DECREASE_UNREAD_EMAILS", email);
        }
        this.setAsRead({
          message_id: email[i].id,
          mail_id: this.selectedMail,
          email: this.selectedEmail,
        });
      }
    },
    markAsUnread(email) {
      //console.log(email);
      for (let i = 0; i < email.length; ++i) {
        this.setAsUnread({
          message_id: email[i].id,
          mail_id: this.selectedMail,
          email: this.selectedEmail,
        }).then((data) => {
          this.loadEmailList();
        });
      }
    },

    setReadMasive() {
      this.setEmailsAsRead({
        mail_id: this.selectedMail,
        email: this.selectedEmail,
        emails_id: this.selected,
      }).then((data) => {
        this.loadEmailList();
      });
    },
    closeEmail() {
      this.selectedThread = null;
    },
    compose() {
      this.composing = true;
      this.responder = false;
      this.newEmail = {
        to: "",
        cc: "",
        bcc: "",
        subject: "",
        content: "",
        mail_id: null,
      };
      this.files = [];
      this.$nextTick(() => {
        this.editor = true;
      });
    },
    closeComposing() {
      this.composing = false;
      for (let x in this.newEmail) {
        this.newEmail[x] = "";
      }
      if (this.newEmail.threadId) {
        delete this.newEmail.threadId;
      }
    },
    sendMail() {
      this.$validator.validateAll("email-form").then((result) => {
        if (result) {
          this.newEmail.mail_id = this.selectedMail;
          let formData = new FormData();
          Object.keys(this.newEmail).forEach((key) => {
            formData.append(key, this.newEmail[key]);
          });
          this.files.forEach((file) => {
            formData.append("files[]", file.file);
          });
          this.sendEmail(formData).then((resp) => {
            if (resp && resp.success) {
              this.$alert(this.$t("emails.alert_send"), "", "");
              this.composing = false;
              this.close.v - btnComposing();
              this.loadEmailList();
            } else {
              this.$store.commit(
                "app/SET_NOTIFICATION",
                { message: resp.msg, show: true, color: "error" },
                { root: true }
              );
            }
          });
        }
      });
    },
    del(email) {
      //console.log(email);
      this.deleteEmail({
        account: this.selectedMail,
        email: this.selectedEmail,
        email_id: email[0].id,
      }).then((data) => {
        this.alertdel = true;
        this.refresh();
      });
    },
    deleteMassive() {
      this.deleteEmails({
        account: this.selectedMail,
        email: this.selectedEmail,
        emails_id: this.selected,
      }).then((data) => {
        this.refresh();
      });
    },

    archive(email, labelId) {
      this.archiveEmail({
        account: this.selectedMail,
        email: this.selectedEmail,
        label_id: labelId,
        actual_label_id: this.bandeja,
        email_id: email[0].id,
      }).then((data) => {
        this.alertarc = true;
        this.refresh();
      });
    },
    archiveMassive(labelId) {
      this.archiveEmails({
        account: this.selectedMail,
        email: this.selectedEmail,
        emails_id: this.selected,
        label_id: labelId,
        actual_label_id: this.bandeja,
      }).then((data) => {
        this.refresh();
      });
    },

    respond() {
      this.responder = true;
      this.selectedThread.opened = [];
    },

    reply() {
      let response = this.selectedThread[this.selectedThread.length - 1];
      this.newEmail.to = response.from;
      this.newEmail.threadId = response.threadId;
      this.newEmail.subject = response.subject.startsWith("RE: ")
        ? response.subject
        : "RE: " + response.subject;

      this.respond();
    },
    checkIfUnread(thread) {
      let mail = null;
      for (mail of thread) {
        if (mail.unread) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<style scoped lang="sass">
.file-upload
  width: 100%
  min-height: 50px

.upload
  min-height: 100px
  border-radius: 10px
  border: dashed grey 2px
  text-align: center
  .message
    padding-top: 15px

.email-row
  cursor: pointer

.unread *
  font-weight: 900 !important
  color: var(--v-primary-base)
  //color: var(--v-gris3-base)
</style>
<style lang="sass">
.tox .tox-notification.tox-notification--in.tox-notification--warning
  display: none

.v-expansion-panel
  background-color: white !important

.v-expansion-panel-content blockquote
  border-left: 2px solid #ccc
  margin-left: 1.5rem
  padding-left: 1rem
.v-expansion-panel .v-expansion-panel-header
  padding: 0px 10px 5px 10px
.v-expansion-panel .v-expansion-panel-content__wrap
  padding-right: 10px
#mail
  .v-card__subtitle, .v-card__text, .v-card__title
    padding: 0px
    padding-left: 25px

.alerts
  position: absolute
  top: 90%
  width: 35%

.alerts-small
  position: absolute
  top: 80%
  width: 55%

.v-expansion-panel
  background-color: var(--v-gris3-base) !important

.email-content
  color: white !important
  *
    min-width: unset !important

#drawer
  background-color: var(--v-gris3-base)
.bandeja
  background-color: var(--v-gris3-base) !important
.tox .tox-edit-area
  border: red 1px solid
  border-color: var(--v-primary-base) !important
  position: absolute !important
  border-radius: 19px
  left: 0px
  right: 0px
  bottom: 50px
  top: 0

.tox .tox-editor-header
  position: absolute
  left: 0
  right: 0
  bottom: -1px
  button
    box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.4)
    margin: 2px 2px 3px 2px
    border-radius: 10px
    $value: clear
#selectorTable
#itemSelection
  $value: d
  height: 35px
  .v-btn
    margin: 5px auto !important

#newMessage

  label
    display: block
    height: 25px
.tox-edit-area__iframe, .tox-toolbar, .tox-statusbar, .tox-toolbar__primary, .tox-toolbar__overflow
  background-color: var(--v-gris3-base) !important

.tox-tbtn
  border: solid 1px red
.tox-tbtn svg *, .tox-tbtn__select-label, .tox-statusbar__text-container *
  fill: var(--v-primary-base) !important
  color: var(--v-primary-base) !important

.tox *, .tox-tinymce
  border-color: transparent !important

.tox-toolbar__primary
  background: var(--v-gris3-base) !important
  border-bottom: 1px solid var(--v-primary-base) !important

.tox-tbtn--enabled, .tox-tbtn:hover
  background-color: var(--v-gris2-base) !important
#tinymce
  overflow: hidden

#newMessage .v-text-field__details
  display: none

.v-list-item
  min-height: 0px
#mail
  .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row), .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row)
    text-align: left !important
  .theme--dark.v-list-item:hover::before
    opacity: 0
  .v-input--selection-controls
    margin-top: 0px
.mobile-row
  td
    box-shadow: 0 2px 0 0 hsla(0,0%,60%,.14), 0 3px 0 -0 hsla(0,0%,60%,.2), 0 1px 0 0 hsla(0,0%,60%,.12)

#email-files
</style>
